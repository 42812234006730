import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import BookIcon from "@mui/icons-material/Book";
import { TimelineData } from "../pages/about/WorkExperience";
export const workTimeLine: TimelineData[] = [
  // {
  //   date: 'May 2024',
  //   title: 'Thesis Project Published',
  //   description:
  //     'AREV: Visualization Infrastructure for Cultural Heritage Exploration',
  //   icon: <BookIcon />,
  // },
  {
    date: "Jan 2023 - May 2024",
    title: "Graduated with Masters in Software Engineering",
    company: "Cal State University, Northridge",
    list: [
      "Graduated with Honors Distinction achieving a 3.97 GPA",
      "Thesis Project, AREV: Visualization Infrastructure for Cultural Heritage Exploration, finalized and published",
      "Relevant Courses: Software Engineering Management, Software Architecture and Design, Software Requirements Analysis and Specification",
    ],
    link: "https://www.csun.edu/",
  },
  // {
  //   date: 'Aug 2023',
  //   title: 'Started Thesis Project',
  //   description:
  //     'AREV: Visualization Infrastructure for Cultural Heritage Exploration',
  //   icon: <BookIcon />,
  // },
  {
    date: "Jan 2022 - Present",
    title: "Full Stack Developer",
    company: "Niagara Bottling LLC.",
    link: "https://www.niagarawater.com/",
    list: [
      "Led the development of B2B web applications using React, TypeScript, and JavaScript, prioritizing features based on user feedback and business impact, resulting in $80 million in savings",
      "Grew and led the development team from 2 to 10 members, increasing project capacity by 400% and improving code deployment frequency by 40%",
      "Implemented an agile/scrum process to execute on roadmap Q3 OKRs, improved communication process of product requirements from product managers to engineers",
      "Partnered with Senior Product Managers as an interim junior PM to translate product requirements for 4 product launches to consumers into technical specifications, ensuring alignment between consumer needs and development efforts",
      "Facilitated clear communication across 5 teams spanning across design, business, offshore, engineering, and dataanalytics teams, ensuring alignment with 5 key business goals",
    ],
    // icon: <WorkIcon />,
  },
  {
    date: "Aug 2019 - Dec 2022",
    title: "Graduated with Bachelor's in Computer Science",
    company: "University of California, Irvine",
    list: [
      "Graduated while achieving a 3.7 GPA",
      "Relevant Courses: Data Structures Implementation and Analysis, Design and Analysis of Algorithms, HumanComputer Interaction, User Interaction Software"
    ],
    link: "https://uci.edu",
  },
  {
    date: "Mar - May 2021",
    title: "Product Manager/Software Engineer Intern",
    company: "myHealthToday",
    list: [
      "Collaborated with cross-functional teams to conceptualize and drive the development of innovative website applications and features.",
      "Led the design process using Figma to create user-centric solutions.",
      "Translated design concepts into functional web applications using JavaScript, HTML, and CSS.",
      "Led stand up meetings and sprint planning to ensure on time delivery for the team.",
    ],
    link: "https://merage.uci.edu/news/2020/12/NVC-Winner-myHealthToday-Bridges-Information-Gap-Between-Doctor-and-Patient.html",
    // icon: <WorkIcon />,
  },
  // {
  //   date: "Oct 2020 - March 2021",
  //   title: "Front-End Software Engineer Intern,",
  //   company: "rThreat",
  //   list: [
  //     "Participate in mentorship meetings and discuss website enhancement ideas, opportunities, challenges, and barriers",
  //     "Collaborated with senior developers and designers to implement responsive designs and ensure cross-browser compatibility.",
  //   ],
  // icon: <WorkIcon />,
  // },
  // {
  //   date: "Aug 2019",
  //   title: "Started Bachelor's in Computer Science",
  //   company: "University of California, Irvine",
  //   list: [],
  //   link: "https://uci.edu"
  // },
];

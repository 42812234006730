import React from "react";
import { Grid, Button, useTheme, useMediaQuery } from "@mui/material";
// import './styles.css';
import EmailIcon from "@mui/icons-material/Email";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Link } from "react-router-dom";

const SubSection = () => {
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const padding10 = {
    padding: `0 3rem`,
    [theme.breakpoints.up("sm")]: {
      padding: `0 2rem`,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: `center`,
    },
    [theme.breakpoints.up("md")]: {
      padding: `0 5rem`,
    },
    [theme.breakpoints.up("lg")]: {
      padding: `0 10rem`,
    },
  };
  const styles = {
    btn: {
      background: "rgba(255, 255, 255, 1)",
      width: "100%",
      color: "black",
      fontWeight: 600,
      fontSize: 22,
      textTransform: "capitalize",
      height: 100,
    },
    icon: {
      width: 36,
      height: 36,
    },
    container: {
      height: isAboveMd ? "calc(100% - 400px)" : "auto",
      alignItems: "center",
    },
  };
  return (
    <Grid
      container
      maxWidth={1488}
      mr="auto"
      ml="auto"
      sx={styles.container}
      style={padding10}
      spacing={3}
      mt={isAboveMd ? 3 : 0}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={{ paddingLeft: 0 }}
        // style={{ paddingLeft: isAboveMd ? 'iinherit' : 0 }}
      >
        <Link
          to="#"
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "mailto:shantseroparslanian@gmail.com";
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={styles.btn}
            endIcon={<EmailIcon sx={styles.icon} />}
          >
            Contact me!
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} md={6} style={{ paddingLeft: isAboveMd ? 24 : 0 }}>
        <a
          className="button"
          href="https://shantarslanianresume.tiiny.site"
          rel="noreferrer"
          target="_blank"
        >
          <Button
            color="secondary"
            variant="contained"
            sx={styles.btn}
            endIcon={<SimCardDownloadIcon sx={styles.icon} />}
          >
            Download my resume
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};

export default SubSection;
